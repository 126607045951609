import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { create } from 'zustand';

export type TFeedbackEngineStore = {
  isConfirmSelected: boolean;
  isClearSelected: boolean;
  selectedCustomLabel: CustomLabelByJob | null;
  selectedClusterId: string | null;
  setSelectedCustomLabel: (selectedCustomLabel: CustomLabelByJob | null) => void;
  setIsConfirmSelected: (isConfirmSelected: boolean) => void;
  setIsClearSelected: (isClearSelected: boolean) => void;
  setSelectedClusterId: (selectedClusterId: string) => void;
  reset: () => void;
};

export const useFeedbackEngineStore = create<TFeedbackEngineStore>()((set) => ({
  isConfirmSelected: false,
  isClearSelected: false,
  selectedCustomLabel: null,
  selectedClusterId: null,
  setSelectedCustomLabel: (selectedCustomLabel) =>
    set((store) => ({
      isConfirmSelected: false,
      isClearSelected: false,
      selectedCustomLabel: selectedCustomLabel === store.selectedCustomLabel ? null : selectedCustomLabel
    })),
  setSelectedClusterId: (selectedClusterId) => set(() => ({ selectedClusterId })),
  setIsConfirmSelected: (isConfirmSelected) =>
    set(() => ({ isClearSelected: false, selectedCustomLabel: null, isConfirmSelected })),
  setIsClearSelected: (isClearSelected) => set(() => ({ isConfirmSelected: false, selectedCustomLabel: null, isClearSelected })),
  reset: () =>
    set(() => ({ isConfirmSelected: false, isClearSelected: false, selectedCustomLabel: null, selectedClusterId: null }))
}));
