import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('FeedbackEventJobUpdateEntry')
export class FeedbackEventJobUpdateEntry {
  @JsonProperty('event_id', String)
  eventId?: string;

  @JsonProperty('bucket_id', String)
  bucketId?: string;

  @JsonProperty('new_label', String)
  newLabel: string;
}

@JsonObject('FeedbackEventJobUpdate')
export class FeedbackEventJobUpdate {
  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('updates', [FeedbackEventJobUpdateEntry])
  updates: FeedbackEventJobUpdateEntry[];
}

@JsonObject('FeedbackEventRequest')
export class FeedbackEventRequest extends RequestCommon {
  @JsonProperty('updates_per_job', [FeedbackEventJobUpdate])
  updatesPerJob: FeedbackEventJobUpdate[];
}

type TFeedbackEventRequestConstructorParams = {
  updatesPerJob: FeedbackEventRequest['updatesPerJob'];
};

export function constructFeedbackEventRequest({ updatesPerJob }: TFeedbackEventRequestConstructorParams): FeedbackEventRequest {
  const instance = new FeedbackEventRequest();
  instance.updatesPerJob = updatesPerJob;

  return instance;
}

type TFeedbackEventJobUpdateConstructorParams = {
  jobId: FeedbackEventJobUpdate['jobId'];
  updates: FeedbackEventJobUpdate['updates'];
};

export function constructFeedbackEventJobUpdate({
  jobId,
  updates
}: TFeedbackEventJobUpdateConstructorParams): FeedbackEventJobUpdate {
  const instance = new FeedbackEventJobUpdate();
  instance.jobId = jobId;
  instance.updates = updates;

  return instance;
}

type TFeedbackEventJobUpdateEntryConstructorParams = {
  eventId?: FeedbackEventJobUpdateEntry['eventId'];
  bucketId?: FeedbackEventJobUpdateEntry['bucketId'];
  newLabel: FeedbackEventJobUpdateEntry['newLabel'];
};

export function constructFeedbackEventJobUpdateEntry({
  eventId,
  bucketId,
  newLabel
}: TFeedbackEventJobUpdateEntryConstructorParams): FeedbackEventJobUpdateEntry {
  const instance = new FeedbackEventJobUpdateEntry();
  if (eventId) {
    instance.eventId = eventId;
  }
  if (bucketId) {
    instance.bucketId = bucketId;
  }
  instance.newLabel = newLabel;

  return instance;
}
