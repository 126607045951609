/* eslint-disable max-len */
function Chip() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15687_40811)">
        <path
          d="M11.5997 3.2002H4.39971C3.73697 3.2002 3.19971 3.73745 3.19971 4.4002V11.6002C3.19971 12.2629 3.73697 12.8002 4.39971 12.8002H11.5997C12.2624 12.8002 12.7997 12.2629 12.7997 11.6002V4.4002C12.7997 3.73745 12.2624 3.2002 11.5997 3.2002Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.59961 1.5998L5.59961 0.799805"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3999 1.5998L10.3999 0.799805"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3.1998 10.3994H0.799805" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.1998 5.59961H0.799805" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.3999 14.3994L10.3999 15.1994"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.59961 14.3994L5.59961 15.1994"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7998 5.59961L15.1998 5.59961"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7998 10.3994L15.1998 10.3994"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.5999 9.59961H6.3999" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_15687_40811">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Chip;
