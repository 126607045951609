import * as React from 'react';
import { useMutation } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { config } from '../../config';
import {
  EventExplorerFiltersValuesRequest,
  EventExplorerStaticRuleFilterRecord
} from '../../components/shared/models/event-explorer/EventExplorerFiltersValuesRequest';
import {
  Bucket,
  EventExplorerFiltersValuesResponse
} from '../../components/shared/models/event-explorer/EventExplorerFiltersValuesResponse';

function fetchEventExplorerFiltersValues(payload: EventExplorerFiltersValuesRequest) {
  return axios.post<{ resource: EventExplorerFiltersValuesResponse }>(config.api.eventExplorerFiltersValues, payload);
}

type UseEventExplorerFiltersValues = {
  jobId: number;
  filterField: string;
  startDate: Date | null;
  endDate: Date | null;
  advancedFilters: EventExplorerStaticRuleFilterRecord[];
};

export function useEventExplorerFiltersValues({
  jobId,
  filterField,
  startDate,
  endDate,
  advancedFilters
}: UseEventExplorerFiltersValues) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useMutation<EventExplorerFiltersValuesResponse, AxiosError, Bucket[]>(
    [
      config.api.eventExplorerFiltersValues,
      {
        jobId,
        filterField,
        startDate: startDate !== null ? startDate.toISOString() : null,
        endDate: endDate !== null ? endDate.toISOString() : null,
        advancedFilters
      }
    ],
    () =>
      fetchEventExplorerFiltersValues(
        new EventExplorerFiltersValuesRequest(
          jobId,
          filterField,
          startDate !== null ? startDate.toISOString() : null,
          endDate !== null ? endDate.toISOString() : null,
          advancedFilters
        )
      ).then((responseData: AxiosResponse<{ resource: EventExplorerFiltersValuesResponse }>) => {
        return jsonConvert.deserializeObject(responseData.data.resource, EventExplorerFiltersValuesResponse);
      })
  );
}

export function useEventExplorerFiltersValuesMutation() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useMutation<EventExplorerFiltersValuesResponse, Response, UseEventExplorerFiltersValues>(
    async ({ jobId, filterField, startDate, endDate, advancedFilters }: UseEventExplorerFiltersValues) => {
      const response = await fetchEventExplorerFiltersValues(
        new EventExplorerFiltersValuesRequest(
          jobId,
          filterField,
          startDate !== null ? startDate.toISOString() : null,
          endDate !== null ? endDate.toISOString() : null,
          advancedFilters
        )
      );

      return jsonConvert.deserializeObject(response.data.resource, EventExplorerFiltersValuesResponse);
    }
  );
}
