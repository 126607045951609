import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ContextEnrichmentField')
export class ContextEnrichmentField {
  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('is_custom', Boolean)
  is_custom?: boolean = false;

  constructor(name?: string, isCustom = false) {
    this.name = name;
    this.is_custom = isCustom;
  }
}
