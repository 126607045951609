import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { Stack } from '@mui/material';
import { ReactNode, useCallback, useEffect } from 'react';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';
import SelectCustomLabelArea from '../SelectCustomLabelArea';
import SaveCancelArea from '../SaveCancelArea';
import { Box } from '@mui/material';
import StyledFeedbackEngineBulkBar from './StyledFeedbackEngineBulkBar';
import { useTranslation } from 'react-i18next';
import ConfirmArcannaDecisionButton from '../ConfirmArcannaDecisionButton';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import ClearMyFeedbackButton from '../ClearMyFeedbackButton';
import SelectClusterArea from '../SelectClusterArea';

export type TFeedbackUpdate = {
  jobId: number;
  eventId?: string;
  bucketId?: string;
  arcannaLabelId: string | null;
  currentLabelId: string | null;
};

type TFeedbackEngineBulkBarProps = {
  customLabels: CustomLabelByJob[];
  children?: ReactNode;
  eventsOrBuckets: TFeedbackUpdate[];
  handleClearSelection: () => void;
  isClearFeedbackHidden?: boolean;
  isMoveToAnotherClusterHidden?: boolean;
};

function FeedbackEngineBulkBar({
  customLabels,
  eventsOrBuckets,
  handleClearSelection,
  isClearFeedbackHidden = false,
  isMoveToAnotherClusterHidden = true
}: TFeedbackEngineBulkBarProps) {
  const reset = useFeedbackEngineStore((store) => store.reset);
  const selectedCustomLabel = useFeedbackEngineStore((store) => store.selectedCustomLabel);
  const setSelectedCustomLabel = useFeedbackEngineStore((store) => store.setSelectedCustomLabel);
  const setIsConfirmSelected = useFeedbackEngineStore((store) => store.setIsConfirmSelected);
  const setIsClearSelected = useFeedbackEngineStore((store) => store.setIsClearSelected);

  const { t } = useTranslation();

  const isHidden = !eventsOrBuckets.length;

  useEffect(() => {
    if (isHidden) {
      reset();
    }
  }, [isHidden, reset]);

  const onCustomLabelSelect = useCallback(
    (customLabel: CustomLabelByJob | null) => {
      setSelectedCustomLabel(customLabel);
    },
    [setSelectedCustomLabel]
  );

  const handleConfirm = useCallback(() => {
    setIsConfirmSelected(true);
  }, [setIsConfirmSelected]);

  const isConfirmDisabled = !eventsOrBuckets.filter(
    (eventOrBucket) => eventOrBucket.arcannaLabelId && eventOrBucket.arcannaLabelId !== ARCANNA_NO_DECISION
  ).length;

  const isClearDisabled = !eventsOrBuckets.filter(
    (eventOrBucket) => eventOrBucket.currentLabelId && eventOrBucket.currentLabelId !== ARCANNA_NO_DECISION
  ).length;

  const handleClear = useCallback(() => {
    setIsClearSelected(true);
  }, [setIsClearSelected]);

  return isHidden ? (
    <></>
  ) : (
    <StyledFeedbackEngineBulkBar>
      <Stack direction="column" gap="8px" flexGrow={1} width="100%">
        <Box>
          <b>{eventsOrBuckets.length}</b>
          <span className="selectedBuckets">{t('feedback:selectedBuckets')}</span>
        </Box>
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <Stack direction="row" gap={4}>
            <ConfirmArcannaDecisionButton isDisabled={isConfirmDisabled} handleConfirm={handleConfirm} />
            <SelectCustomLabelArea
              customLabels={customLabels}
              selectedLabel={selectedCustomLabel}
              onSelect={onCustomLabelSelect}
            />
            {!isClearFeedbackHidden && <ClearMyFeedbackButton isDisabled={isClearDisabled} handleClear={handleClear} />}
            {!isMoveToAnotherClusterHidden && <SelectClusterArea />}
          </Stack>
          <SaveCancelArea eventsOrBuckets={eventsOrBuckets} handleClearSelection={handleClearSelection} />
        </Stack>
      </Stack>
    </StyledFeedbackEngineBulkBar>
  );
}

export default FeedbackEngineBulkBar;
