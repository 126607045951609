import FeedbackEngineBulkBar from './FeedbackEngineBulkBar';
import FeedbackEngineSingleBar from './FeedbackEngineSingleBar';
import LabelColumn from './LabelColumn';

const FeedbackEngine = {
  FeedbackEngineBulkBar,
  FeedbackEngineSingleBar,
  LabelColumn
};

export default FeedbackEngine;
