/* eslint-disable max-len */

export default function Database() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 4.16675C17.5 5.54746 14.1421 6.66675 10 6.66675C5.85786 6.66675 2.5 5.54746 2.5 4.16675M17.5 4.16675C17.5 2.78604 14.1421 1.66675 10 1.66675C5.85786 1.66675 2.5 2.78604 2.5 4.16675M17.5 4.16675V15.8334C17.5 17.2167 14.1667 18.3334 10 18.3334C5.83333 18.3334 2.5 17.2167 2.5 15.8334V4.16675M17.5 10.0001C17.5 11.3834 14.1667 12.5001 10 12.5001C5.83333 12.5001 2.5 11.3834 2.5 10.0001"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
