import { Button, Menu, Stack } from '@mui/material';
import StyledDropdownButton from './StyledDropdownButton.styles';
import { useState } from 'react';
import { Icon, EIcon, Spinner } from '@arcanna/generic';
import { MouseEvent } from 'react';

type TDropdownButtonItem = {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
};

type TDropdownButtonProps = {
  mainButton: TDropdownButtonItem;
  optionButtons: TDropdownButtonItem[];
  isLoading?: boolean;
  disabled?: boolean;
  dataTestId?: string;
};

function DropdownButton({ mainButton, optionButtons, isLoading, disabled, dataTestId }: TDropdownButtonProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const loadingOption = optionButtons.find((optionButton) => optionButton.isLoading);
  const isMainButtonLoading = isLoading || Boolean(loadingOption);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    if (anchorEl !== event.currentTarget) {
      // @ts-expect-error TS(2345): Argument of type 'EventTarget & HTMLButtonElement'...
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const isOpen = !loadingOption && Boolean(anchorEl) && Boolean(optionButtons?.length);

  return (
    <StyledDropdownButton>
      <Button
        sx={isMainButtonLoading ? { pointerEvents: 'none' } : {}}
        className="main-button"
        variant="contained"
        color="primary"
        onClick={mainButton.onClick}
        endIcon={isMainButtonLoading && <Spinner />}
        disabled={disabled}
      >
        {loadingOption ? loadingOption.text : mainButton.text}
      </Button>
      <Button
        sx={isMainButtonLoading ? { pointerEvents: 'none' } : {}}
        className="arrow-wrapper"
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disabled}
        data-test-id={dataTestId}
      >
        <Icon fontSize="small" name={isOpen ? EIcon.ChevronDown : EIcon.ChevronUp} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{ className: 'MuiList--naked' }}
      >
        {optionButtons.map((option, index) => (
          <Stack width="220px" key={index}>
            <Button
              sx={{ borderRadius: 0, border: 0 }}
              fullWidth
              color="primary"
              variant="contained"
              onClick={option.onClick}
              disabled={disabled}
            >
              {option.text}
            </Button>
          </Stack>
        ))}
      </Menu>
    </StyledDropdownButton>
  );
}

export default DropdownButton;
