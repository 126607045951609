import { Box, BoxProps, styled } from '@mui/material';

const StyledFeedbackEngineBulkBar = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: 'calc(100% - 150px)',
  height: '112px',
  display: 'flex',
  padding: '16px 12px',
  backgroundColor: theme.palette.secondary[900],
  border: `1px solid ${theme.palette.secondary[700]}`,
  borderBottom: 0,
  borderRadius: '4px 4px 0 0',
  zIndex: 5,

  b: {
    color: theme.palette.secondary[0]
  },

  '.selectedBuckets': {
    marginLeft: '8px',
    color: theme.palette.secondary[300]
  },

  '.customLabel': {
    color: theme.palette.secondary[100],
    width: '136px',
    maxHeight: '36px',
    border: `1px solid ${theme.palette.secondary[600]}`
  }
}));

export default StyledFeedbackEngineBulkBar;
