import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('JobClustersIdsSuggestionsResponse')
export class JobClustersIdsSuggestionsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('suggestions', [String])
  suggestions: string[];
}
