import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { showErrorNotification } from '../../components/shared/utilities/notification';
import { JobClustersIdsSuggestionsRequest } from '../../components/shared/models/job/JobClustersIdsSuggestionsRequest';
import { JobClustersIdsSuggestionsResponse } from '../../components/shared/models/job/JobClustersIdsSuggestionsResponse';

type useGetClustersListProps = {
  jobId: number;
  searchValue: string;
};

export default function useClustersIdsSuggestions({ jobId, searchValue }: useGetClustersListProps) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const { t } = useTranslation(['common']);
  return useQuery<JobClustersIdsSuggestionsResponse, { data: ErrorResponse }>(
    [config.api.clustersIdsSuggestions, jobId, searchValue],
    () =>
      axios
        .post<{
          resource: JobClustersIdsSuggestionsResponse;
        }>(config.api.clustersIdsSuggestions, new JobClustersIdsSuggestionsRequest(jobId, searchValue))
        .then((responseData: AxiosResponse<{ resource: JobClustersIdsSuggestionsResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobClustersIdsSuggestionsResponse)
        ),
    {
      onError: (error) => {
        showErrorNotification(t('error'), error.data.resource.request.reason);
      }
    }
  );
}
