/* eslint-disable max-len */
function Puzzle() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="puzzle-piece-02" clipPath="url(#clip0_14770_3261)">
        <path
          id="Icon"
          d="M10.0001 1.6665L13.0001 4.6665C15.0001 -0.583496 20.5834 4.99984 15.3334 6.99984L18.3334 9.99984L15.3334 12.9998C13.3334 7.74984 7.75008 13.3332 13.0001 15.3332L10.0001 18.3332L7.00008 15.3332C5.00008 20.5832 -0.583252 14.9998 4.66675 12.9998L1.66675 9.99984L4.66675 6.99984C6.66675 12.2498 12.2501 6.6665 7.00008 4.6665L10.0001 1.6665Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14770_3261">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Puzzle;
