import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BackspaceIcon from 'src/components/shared/icons/BackspaceIcon';

type TClearMyFeedbackButtonProps = {
  isDisabled: boolean;
  handleClear: () => void;
};

function ClearMyFeedbackButton({ isDisabled, handleClear }: TClearMyFeedbackButtonProps) {
  const { t } = useTranslation(['feedback']);

  return (
    <Button size="small" variant="tertiary" endIcon={<BackspaceIcon />} onClick={handleClear} disabled={isDisabled}>
      {t('feedback:clearMyFeedback')}
    </Button>
  );
}

export default ClearMyFeedbackButton;
