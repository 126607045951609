import { useMemo } from 'react';
import { ARCANNA_BUCKET_ID } from 'src/components/pages/JobEventExplorer/utils';
import { useEventInfo } from 'src/data-access';

export function useEventBucketId(eventId: string, jobId: string): { bucketId: string | undefined } {
  const eventInfoQuery = useEventInfo({ jobId: Number(jobId), eventId: eventId, includeInternal: true });

  const bucketId = useMemo(
    () => eventInfoQuery?.data?.entries?.find((entry) => entry.name === ARCANNA_BUCKET_ID)?.value,
    [eventInfoQuery]
  );

  return {
    bucketId
  };
}
