import { LabelCircle } from '@arcanna/components';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import StyledCustomLabelPill from './StyledCustomLabelPill.styles';
import { Icon, EIcon } from '@arcanna/generic';

type TCustomLabelPillProps = {
  label: string;
  color: string;
  isDisabled?: boolean;
  isMenuOpen?: boolean;
  onEditClick?: (value: HTMLElement | null) => void;
  onDelete?: () => void;
  isDeleteDisabled?: boolean;
};

function CustomLabelPill({ label, color, isDisabled, isMenuOpen, onEditClick, onDelete }: TCustomLabelPillProps) {
  const { palette } = useTheme();
  const anchorRef = useRef(null);

  return (
    <StyledCustomLabelPill ref={anchorRef} direction="row" gap="16px" alignItems="center" isMenuOpen={isMenuOpen}>
      <Stack direction="row" gap="8px" alignItems="center">
        <LabelCircle hexColor={color} />
        <Typography fontSize="14px" lineHeight="24px">
          {label}
        </Typography>
      </Stack>
      {!isDisabled && (onEditClick || onDelete) && (
        <Stack direction="row" gap="8px" fontSize="12px" alignItems="center" color={palette.secondary[200]}>
          {onEditClick && (
            <Box
              data-test-id={`edit-${label.toLowerCase()}`}
              onClick={() => onEditClick(anchorRef.current)}
              className="icon editIcon"
            >
              <Icon name={EIcon.Edit01} fontSize="inherit" color="inherit" />
            </Box>
          )}
          {onDelete && (
            <Box onClick={onDelete} className="icon deleteIcon" data-test-id={`remove-${label.toLowerCase()}`}>
              <Icon name={EIcon.ActionClose} className="pillIcon" onClick={onDelete} color="inherit" />
            </Box>
          )}
        </Stack>
      )}
    </StyledCustomLabelPill>
  );
}

export default CustomLabelPill;
