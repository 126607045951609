import { ButtonProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import StyledRHFSubmitButton from './StyledRHFSubmitButton.styles';
import { Spinner } from '@arcanna/generic';

export type TRHFSubmitButtonProps = ButtonProps & {
  text: string;
  isLoading?: boolean;
};

function RHFSubmitButton({
  text,
  disabled,
  variant = 'contained',
  color = 'primary',
  isLoading,
  ...other
}: TRHFSubmitButtonProps) {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <StyledRHFSubmitButton
      variant={variant}
      color={color}
      type="submit"
      endIcon={isSubmitting || isLoading ? <Spinner /> : null}
      disabled={disabled}
      isSubmitting={isSubmitting || Boolean(isLoading)}
      {...other}
    >
      {text}
    </StyledRHFSubmitButton>
  );
}

export default RHFSubmitButton;
