import { QueryParams } from '@arcanna/utils';
import { EFilterOperatorWithValue } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';
import { config } from 'src/config';
import { v4 as uuidv4 } from 'uuid';
import { ELinkGeneratorPages } from './RedirectLinkGenerator.types';
import { ARCANNA_BUCKET_ID } from 'src/components/pages/JobEventExplorer/utils';

export function constructFeedbackBucketIdRedirectURL({ jobId, bucketId }: { jobId: string; bucketId: string }) {
  const advancedFilters = [
    { id: uuidv4(), field: 'bucket_id', operator: EFilterOperatorWithValue.is, value: bucketId, status: 'active' }
  ];
  const excludeProcessed = false;
  const filtersQP = QueryParams.encode(
    QueryParams.filtersToParams({
      job_id: jobId,
      advancedFilters,
      excludeProcessed
    })
  );

  return `${config.routes.feedbackFlow.replace(':id', jobId)}?filters=${filtersQP}`;
}

export function constructExplorerBucketIdRedirectURL({ jobId, bucketId }: { jobId: string; bucketId: string }) {
  const advancedFilters = [
    {
      id: uuidv4(),
      label: ARCANNA_BUCKET_ID,
      field: ARCANNA_BUCKET_ID,
      operator: EFilterOperatorWithValue.is,
      status: 'active',
      value: bucketId
    }
  ];
  const filtersQP = QueryParams.encode(QueryParams.filtersToParams({ job_id: jobId, advancedFilters }));

  return `${config.routes.eventExplorer.replace(':id', jobId)}?filters=${filtersQP}`;
}

export function constructExplorerEventIdRedirectURL({ jobId, eventId }: { jobId: string; eventId: string }) {
  const advancedFilters = [
    {
      id: uuidv4(),
      label: '_id',
      field: '_id',
      operator: EFilterOperatorWithValue.is,
      status: 'active',
      value: eventId
    }
  ];
  const filtersQP = QueryParams.encode(
    QueryParams.filtersToParams({
      job_id: jobId,
      advancedFilters,
      eventId: eventId
    })
  );

  return `${config.routes.eventExplorer.replace(':id', jobId)}?filters=${filtersQP}`;
}

export function constructClusterClusterIdRedirectURL({ jobId, clusterId }: { jobId: string; clusterId: string }) {
  const advancedFilters = [
    {
      id: uuidv4(),
      label: 'Cluster ID',
      field: 'cluster_id',
      operator: EFilterOperatorWithValue.is,
      status: 'active',
      value: clusterId
    }
  ];
  const filtersQP = QueryParams.encode(QueryParams.filtersToParams({ job_id: jobId, advancedFilters }));

  return `${config.routes.jobClusters.replace(':id', jobId)}?filters=${filtersQP}`;
}

export function constructExplorerClusterIdRedirectURL({ jobId, clusterId }: { jobId: string; clusterId: string }) {
  const advancedFilters = [
    {
      id: uuidv4(),
      label: 'arcanna.clustering.cluster_id',
      field: 'arcanna.clustering.cluster_id',
      operator: EFilterOperatorWithValue.is,
      status: 'active',
      value: clusterId
    }
  ];
  const filtersQP = QueryParams.encode(QueryParams.filtersToParams({ job_id: jobId, advancedFilters }));

  return `${config.routes.eventExplorer.replace(':id', jobId)}?filters=${filtersQP}`;
}

export const getIsDIJobRelated = (pageId: ELinkGeneratorPages) =>
  [
    ELinkGeneratorPages.feedbackWithBucketId,
    ELinkGeneratorPages.explorerWithBucketId,
    ELinkGeneratorPages.explorerWithEventId
  ].includes(pageId);
