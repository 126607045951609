import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('EventClusterUpdate')
export class EventClusterUpdate {
  @JsonProperty('entry_id', String)
  entry_id: string;

  @JsonProperty('cluster_id_destination', String)
  cluster_id_destination: string;
}

@JsonObject('EventUpdateClusterRequest')
export class EventUpdateClusterRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('updates', [EventClusterUpdate])
  updates: EventClusterUpdate[];

  constructor(jobId: number, updates: EventClusterUpdate[]) {
    super();
    this.job_id = jobId;
    this.updates = updates;
  }
}
