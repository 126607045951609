import { useMemo } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';
import { noDecisionResult, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { Cell } from '@tanstack/react-table';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { TECDIExplorerTableRowData } from '../../../../pages/Explorer/components/ECDIExplorer/components/ECDIExplorerTable/ECDIExplorerTable.types';
import { useFeedbackEngineStore } from 'src/_srcMUI/shared/components/FeedbackEngine/store/FeedbackEngineStore';
import { TRCAExplorerTableRowData } from '@arcanna/pages/Explorer/components/RCAExplorer/components/RCAExplorerTable/RCAExplorerTable.types';

type TLabelColumnProps = {
  cell: Cell<TECDIExplorerTableRowData, string> | Cell<TRCAExplorerTableRowData, string>;
  isDisplayedOnTwoRows: boolean;
  jobInfoCustomLabels: CustomLabelByJob[];
  consensus: string | null;
};

function getLabel(labelId: string, currentJobInfoLabels: CustomLabelByJob[]) {
  const value: CustomLabelType | undefined =
    labelId === undecidedConsensus.id
      ? undecidedConsensus
      : currentJobInfoLabels.find((customLabel) => customLabel.id === labelId);
  return value;
}

function getMatchingLabel(labelId: string, rowSelectedLabel: CustomLabelByJob | null, currentJobInfoLabels: CustomLabelByJob[]) {
  const value: CustomLabelType | undefined =
    labelId === undecidedConsensus.id
      ? undecidedConsensus
      : currentJobInfoLabels.find(
          (customLabel) =>
            // name and id must concide when a label is selected
            (customLabel.id === labelId && rowSelectedLabel != null && rowSelectedLabel.name == customLabel.name) ||
            // otherwise just displayed the new label retrieved by API (the one previously saved)
            (customLabel.id === labelId && rowSelectedLabel == null)
        );
  return value;
}

function LabelColumn({ cell, isDisplayedOnTwoRows, jobInfoCustomLabels, consensus }: TLabelColumnProps) {
  const selectedCustomLabel = useFeedbackEngineStore((store) => store.selectedCustomLabel);
  const isConfirmSelected = useFeedbackEngineStore((store) => store.isConfirmSelected);
  const isClearSelected = useFeedbackEngineStore((store) => store.isClearSelected);

  const row = cell.row;
  const rowSelectedLabel = row.getIsSelected() ? selectedCustomLabel : null;
  const rowData = row.original;
  const labelId = rowData.label;
  const jobId = Number(rowData.job_id);
  const currentJobInfoLabels = jobInfoCustomLabels.filter((customLabel) => {
    return customLabel.jobIds != undefined ? customLabel.jobIds.includes(jobId) : true;
  });
  const newLabel = useMemo(() => {
    if (isConfirmSelected && row.getIsSelected()) {
      return getLabel(labelId, currentJobInfoLabels);
    }

    if (isClearSelected && row.getIsSelected()) {
      return undefined;
    }

    const matchingLabel = getMatchingLabel(rowSelectedLabel?.id || '', rowSelectedLabel, currentJobInfoLabels);
    const consensusLabel = getLabel(consensus ?? '', currentJobInfoLabels);

    return matchingLabel || consensusLabel;
  }, [row, currentJobInfoLabels, rowSelectedLabel, consensus, labelId, isConfirmSelected, isClearSelected]);

  const hasConsensusOverwritten = false; // TODO - consensusOverwrittenBy once implemented

  let oldValue: CustomLabelType | undefined = getLabel(labelId, currentJobInfoLabels);

  if (oldValue == null) {
    oldValue = noDecisionResult;
  }

  const hasNewValue = newLabel && newLabel !== oldValue && newLabel !== undefined;

  const isFeedbackSelected = useMemo(
    () => (Boolean(newLabel) && newLabel !== oldValue) || (isConfirmSelected && selectedCustomLabel?.id !== undefined),
    [newLabel, oldValue, isConfirmSelected, selectedCustomLabel]
  );

  const getContent = (width?: number) =>
    oldValue ? (
      <Stack
        direction={isDisplayedOnTwoRows ? 'column' : 'row'}
        alignItems={isDisplayedOnTwoRows ? 'flex-start' : 'center'}
        gap={1}
      >
        <CustomLabel
          name={oldValue.name}
          dataTestId="label-column-value"
          hexColor={oldValue.hexColor}
          disabled={isFeedbackSelected}
          strike={isFeedbackSelected}
          fontSize="12px"
          lineHeight="20px"
          disableColorCircle={oldValue === noDecisionResult || oldValue.name === undecidedConsensus.name}
          isConfirmed={newLabel === oldValue}
          hasConsensusOverwritten={hasConsensusOverwritten && newLabel == oldValue}
          width={width && (hasNewValue && !isDisplayedOnTwoRows ? width / 2 : width)}
        />
        {hasNewValue && (
          <CustomLabel
            name={newLabel.name}
            dataTestId="new-label-column-value"
            disableColorCircle={newLabel === undecidedConsensus}
            hexColor={newLabel.hexColor}
            fontSize="12px"
            lineHeight="20px"
            hasConsensusOverwritten={hasConsensusOverwritten}
            width={width && !isDisplayedOnTwoRows ? width / 2 : undefined}
          />
        )}
      </Stack>
    ) : (
      <></>
    );

  return oldValue ? (
    <Tooltip
      title={getContent()}
      enterDelay={1000}
      enterNextDelay={1000}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'unset',
            width: 'auto'
          }
        }
      }}
    >
      {getContent(cell.column.getSize() - 32)}
    </Tooltip>
  ) : null;
}

export default LabelColumn;
