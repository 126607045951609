import { JsonObject, JsonProperty } from 'json2typescript';
import { IntegrationParamsComplex, IntegrationParamsSimple } from './ContextEnrichmentFetchRequest';
import { FilterRecord } from '../filters/FilterRecord';
import { ContextEnrichmentField } from './ContextEnrichmentField';

@JsonObject('ContextEnrichmentRecord')
export class ContextEnrichmentRecord {
  // @JsonProperty('enrichment_id', String) // database id
  // // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  // enrichment_id: string = undefined;

  @JsonProperty('context_id', String)
  contextId: string;

  @JsonProperty('enrichment_name', String) // internalId
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  enrichment_name: string = undefined;

  @JsonProperty('friendly_name', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  friendly_name: string = undefined;

  @JsonProperty('integration_id', String)
  integration_id: string;

  @JsonProperty('integration_params_simple', Array<IntegrationParamsSimple>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Integr...
  integration_params_simple: Array<IntegrationParamsSimple> = undefined;

  @JsonProperty('integration_params_complex', Array<IntegrationParamsComplex>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Integr...
  integration_params_complex: Array<IntegrationParamsComplex> = undefined;

  @JsonProperty('body_value', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  body_value: string = undefined;

  @JsonProperty('body_type', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  body_type: string = undefined;

  @JsonProperty('field_mapping', [ContextEnrichmentField], true)
  field_mapping: ContextEnrichmentField[] | undefined = undefined;

  @JsonProperty('filters', Array<FilterRecord>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Filter...
  filters: Array<FilterRecord> = undefined;
}
