import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { EventUpdateClusterResponse } from '../../components/shared/models/events/EventUpdateClusterResponse';
import { config } from '../../config';
import { EventUpdateClusterRequest } from '../../components/shared/models/events/EventUpdateClusterRequest';

type UseUpdateEventCluster = {
  jobId: number;
};
export function useUpdateEventCluster({ jobId }: UseUpdateEventCluster) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: EventUpdateClusterRequest) => {
      return axios.post<{ resource: EventUpdateClusterResponse }>(config.api.eventsUpdateCluster, payload);
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries([config.api.eventExplorerBatch, { jobId }]);
      }
    }
  );
}
