/* eslint-disable @typescript-eslint/no-empty-function */
import { Stack, Typography, useTheme } from '@mui/material';
import StyledFeedbackEngineSingleBar from './StyledFeedbackEngineSingleBar';
import ConfirmArcannaDecisionButton from '../ConfirmArcannaDecisionButton';
import ClearMyFeedbackButton from '../ClearMyFeedbackButton';
import SelectCustomLabelArea from '../SelectCustomLabelArea';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { useCallback, useMemo } from 'react';
import { ARCANNA_NO_DECISION, ARCANNA_NO_DECISION_DISPLAY_VALUE } from 'src/components/pages/JobEventExplorer/utils';
import ExpandedDrawerNavigation from '../../ExpandedDrawerNavigation';
import { TExpandedDrawerNavigationProps } from '../../ExpandedDrawerNavigation/ExpandedDrawerNavigation';
import {
  constructFeedbackEventJobUpdate,
  constructFeedbackEventJobUpdateEntry,
  constructFeedbackEventRequest
} from '@arcanna/models/Event/FeedbackEventRequest';
import useFeedback from '@arcanna/requests/Event/useFeedback';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { TFeedbackUpdate } from '../FeedbackEngineBulkBar/FeedbackEngineBulkBar';

type TFeedbackEngineSingleBarProps = {
  customLabels: CustomLabelByJob[];
  eventOrBucket: TFeedbackUpdate;
  navigationProps: TExpandedDrawerNavigationProps;
};

function FeedbackEngineSingleBar({ customLabels, eventOrBucket, navigationProps }: TFeedbackEngineSingleBarProps) {
  const theme = useTheme();

  const { jobId } = useJobIdFromUrl();

  const { mutateAsync: updateFeedback } = useFeedback();

  const isConfirmDisabled = useMemo(
    () => !(eventOrBucket.arcannaLabelId && eventOrBucket.arcannaLabelId !== ARCANNA_NO_DECISION),
    [eventOrBucket.arcannaLabelId]
  );

  const isClearDisabled = useMemo(
    () => !(eventOrBucket.currentLabelId && eventOrBucket.currentLabelId !== ARCANNA_NO_DECISION),
    [eventOrBucket.currentLabelId]
  );

  const arcannasDecisionLabel = useMemo(
    () => customLabels.find((label) => label.id === eventOrBucket.arcannaLabelId)?.name || ARCANNA_NO_DECISION_DISPLAY_VALUE,
    [eventOrBucket.arcannaLabelId, customLabels]
  );

  const selectedLabel = useMemo(
    () => customLabels.find((label) => label.id === eventOrBucket.currentLabelId) ?? null,
    [eventOrBucket.currentLabelId, customLabels]
  );

  const handleFeedbackSave = useCallback(
    async (labelId: string) => {
      const updates = [
        constructFeedbackEventJobUpdateEntry({
          eventId: eventOrBucket.eventId,
          bucketId: eventOrBucket.bucketId,
          newLabel: labelId
        })
      ];

      const updatesPerJob = [constructFeedbackEventJobUpdate({ jobId, updates })];

      const payload = constructFeedbackEventRequest({ updatesPerJob });

      await updateFeedback(payload);
    },
    [eventOrBucket.bucketId, eventOrBucket.eventId, jobId, updateFeedback]
  );

  const handleConfirm = useCallback(() => {
    eventOrBucket.arcannaLabelId && handleFeedbackSave(eventOrBucket.arcannaLabelId);
  }, [eventOrBucket.arcannaLabelId, handleFeedbackSave]);

  const handleLabelSelection = useCallback(
    (selectedLabel: CustomLabelByJob | null) => {
      selectedLabel?.id && handleFeedbackSave(selectedLabel.id);
    },
    [handleFeedbackSave]
  );

  return (
    <StyledFeedbackEngineSingleBar>
      <Stack direction="row" sx={{ borderBottom: '1px solid', borderColor: theme.palette.secondary[700] }}>
        <Stack p={2} gap={1} flexGrow={1} sx={{ borderRight: '1px solid', borderColor: theme.palette.secondary[700] }}>
          <Typography variant="caption" color={theme.palette.secondary[300]}>
            Arcanna&#39;s decision
          </Typography>
          <Typography variant="body2">{arcannasDecisionLabel}</Typography>
        </Stack>
        {navigationProps.currentIndex && (
          <Stack p={2} gap={1}>
            <Typography variant="caption" color={theme.palette.secondary[300]}>
              Event
            </Typography>
            <ExpandedDrawerNavigation {...navigationProps} />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" gap={2} alignItems="flex-start" p={1} pt={2}>
        <Stack direction="column" gap={2}>
          <ConfirmArcannaDecisionButton isDisabled={isConfirmDisabled} handleConfirm={handleConfirm} />
          <ClearMyFeedbackButton isDisabled={isClearDisabled} handleClear={() => {}} />
        </Stack>
        <SelectCustomLabelArea customLabels={customLabels} selectedLabel={selectedLabel} onSelect={handleLabelSelection} />
      </Stack>
    </StyledFeedbackEngineSingleBar>
  );
}

export default FeedbackEngineSingleBar;
