import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircledCheckmark from 'src/components/shared/icons/CircledCheckmark';

type TConfirmArcannaDecisionButtonProps = {
  isDisabled: boolean;
  handleConfirm: () => void;
};

function ConfirmArcannaDecisionButton({ isDisabled, handleConfirm }: TConfirmArcannaDecisionButtonProps) {
  const { t } = useTranslation(['feedback']);

  return (
    <Button
      size="small"
      color="secondary"
      variant="outlined"
      disabled={isDisabled}
      endIcon={<CircledCheckmark />}
      onClick={handleConfirm}
      sx={{
        minWidth: '250px'
      }}
    >
      {t('feedback:confirmDecisionsUnified')}
    </Button>
  );
}

export default ConfirmArcannaDecisionButton;
