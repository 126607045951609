import { EIcon, Icon } from '@arcanna/generic';
import { IconButton, IconButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TRefreshButtonProps = {
  onRefresh: () => void;
  dataTestId?: string;
  iconVariant?: 'circle' | 'normal';
} & IconButtonProps;

export default function RefreshButton({ onRefresh, dataTestId, iconVariant = 'normal', ...otherProps }: TRefreshButtonProps) {
  const { t } = useTranslation(['common']);

  const getIcon = () => {
    switch (iconVariant) {
      case 'circle':
        return <Icon name={EIcon.RefreshCircle} fontSize={'small'} />;
      case 'normal':
      default:
        return <Icon name={EIcon.Refresh} fontSize={'small'} />;
    }
  };

  return (
    <IconButton
      data-test-id={dataTestId ? dataTestId : 'refresh'}
      onClick={onRefresh}
      color={'secondary'}
      variant={'contained'}
      size={'small'}
      title={t('refresh')}
      {...otherProps}
    >
      {getIcon()}
    </IconButton>
  );
}
