import React from 'react';
import { config } from '../../../config';
import { RouteType } from '../models/route/RouteType';

const OrangeFooter = React.lazy(() => import('../../../themes/orange/components/Footer'));
const SiscaleFooter = React.lazy(() => import('../../../themes/siscale/components/Footer'));

type PropType = {
  route: RouteType;
};
export function Footer({ route }: PropType) {
  return (
    <React.Suspense fallback={<></>}>
      {(() => {
        if (route?.path?.startsWith('/jobs/feedback-flow') || route?.path?.startsWith('/jobs/event-explorer')) {
          return <></>;
        }
        if (config.branding.folder === 'orange') {
          return <OrangeFooter />;
        }
        return <SiscaleFooter />;
      })()}
    </React.Suspense>
  );
}
