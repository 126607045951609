import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobClustersIdsSuggestionsRequest')
export class JobClustersIdsSuggestionsRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('search_value', String)
  search_value: string;

  constructor(job_id: number, search_value: string) {
    super();
    this.job_id = job_id;
    this.search_value = search_value;
  }
}
