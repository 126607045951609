import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios from 'axios';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { config } from 'src/config';
import { eventKeys } from './keys';
import { FeedbackEventRequest } from '@arcanna/models/Event/FeedbackEventRequest';

function useFeedback() {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  const URL = config.api.events.feedback;
  const axiosFunction = useCallback((body: object) => axios.post<ResourceWrapper<ResponseCommon>>(URL, body), [URL]);

  return useMutation(
    [config.api.events.feedback],
    (payload: FeedbackEventRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(eventKeys.gets());
        await queryClient.invalidateQueries([config.api.eventExplorerBatch]);
      }
    }
  );
}

export default useFeedback;
