/* eslint-disable max-len */
function Square() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_15565_4089)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.71429 0C1.25963 0 0.823594 0.180612 0.502103 0.502103C0.180612 0.823594 0 1.25963 0 1.71429L0 14.2857C0 14.7404 0.180612 15.1764 0.502103 15.4979C0.823594 15.8194 1.25963 16 1.71429 16H14.2857C14.7404 16 15.1764 15.8194 15.4979 15.4979C15.8194 15.1764 16 14.7404 16 14.2857V1.71429C16 1.25963 15.8194 0.823594 15.4979 0.502103C15.1764 0.180612 14.7404 0 14.2857 0L1.71429 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_15565_4089">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Square;
