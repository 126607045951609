import { ReactNode } from 'react';
import { EIcon } from './Icon.types';
import ChevronRight from './icons/ChevronRight';
import ExternalLink from './icons/ExternalLink';
import Integration from './icons/Integration';
import Job from './icons/Job';
import Lock from './icons/Lock';
import Close from './icons/Close';
import DotDotDotVertical from './icons/DotDotDotVertical';
import Home from './icons/Home';
import MessageAlertSquare from './icons/MessageAlertSquare';
import Settings04 from './icons/Settings04';
import Edit16 from './icons/Edit16';
import EyeSlashed from './icons/EyeSlashed';
import Delete from './icons/Delete';
import Plus from './icons/Plus';
import Minimize01 from './icons/Minimize01';
import ArtificialIntelligenceSpark from './icons/ArtificialIntelligenceSpark';
import Refresh from './icons/Refresh';
import Expand01 from './icons/Expand01';
import Eye from './icons/Eye';
import GoTo from './icons/GoTo';
import CheckCircleOutlined from './icons/CheckCircleOutlined';
import ChipClose from './icons/ChipClose';
import Edit01 from './icons/Edit01';
import ActionClose from './icons/ActionClose';
import InfoCircle from './icons/InfoCircle';
import Save02 from './icons/Save02';
import ActionSearch from './icons/ActionSearch';
import ChevronDown from './icons/ChevronDown';
import ChevronUp from './icons/ChevronUp';
import Pause from './icons/Pause';
import Start from './icons/Start';
import FilterLines from './icons/FilterLines';
import RefreshCCW01 from './icons/RefreshCCW01';
import Container from './icons/Container';
import Activity from './icons/Activity';
import Error from './icons/Error';
import Warning from './icons/Warning';
import Success from './icons/Success';
import Info from './icons/Info';
import TransitionArrowRight from './icons/TransitionArrowRight';
import FaceSmile from './icons/FaceSmile';
import AlertTriangle from './icons/AlertTriangle';
import Download01 from './icons/Download01';
import Calendar from './icons/Calendar';
import ActivityHeart from './icons/ActivityHeart';
import LockUnlocked from './icons/LockUnlocked';
import Folder from './icons/Folder';
import FolderPlus from './icons/FolderPlus';
import Hand from './icons/Hand';
import Pin from './icons/Pin';
import Columns from './icons/Columns';
import Layout from './icons/Layout';
import ArrowUp from './icons/ArrowUp';
import ArrowDown from './icons/ArrowDown';
import MinusCircle from './icons/MinusCircle';
import PlusCircle from './icons/PlusCircle';
import Feedback from './icons/Feedback';
import PinLeft from './icons/PinLeft';
import ClipboardPlus from './icons/ClipboardPlus';
import Copy from './icons/Copy';
import NotFound from './icons/NotFound';
import InternalError from './icons/InternalError';
import Code02 from './icons/Code02';
import File04 from './icons/File04';
import Stars01 from './icons/Stars01';
import Variable from './icons/Variable';
import Terminal from './icons/Terminal';
import LinkExternal01 from './icons/LinkExternal01';
import Python from './icons/Python';
import Shuffle01 from './icons/Shuffle01';
import DeleteArrow from './icons/DeleteArrow';
import Play from './icons/Play';
import CPU from './icons/CPU';
import Switch from './icons/Switch';
import CaretUp from './icons/CaretUp';
import CaretDown from './icons/CaretDown';
import ApiOutlined from './icons/ApiOutlined';
import DotsDrag from './icons/DotsDrag';
import CodeBlockModal from './icons/CodeBlockModal';
import ContextEnrichmentModal from './icons/ContextEnrichmentModal';
import EnrichmentModal from './icons/EnrichmentModal';
import PostDecisionModal from './icons/PostDecisionModal';
import TicketingModal from './icons/TicketingModal';
import ThreeDots from './icons/ThreeDots';
import SwapRight from './icons/SwapRight';
import ChevronLeft from './icons/ChevronLeft';
import ChevronLeftDouble from './icons/ChevronLeftDouble';
import ChevronRightDouble from './icons/ChevronRightDouble';
import Send from './icons/Send';
import ArcannaDarkLogo from './icons/ArcannaDarkLogo';
import ArrowNarrowRight from './icons/ArrowNarrowRight';
import Database from './icons/Database';
import Puzzle from './icons/Puzzle';
import Chip from './icons/Chip';
import Brain from './icons/Brain';
import Flash from './icons/Flash';
import Square from './icons/Square';
import RefreshCircle from './icons/RefreshCircle';

export const SVG_ICONS: Record<
  EIcon,
  {
    icon: ReactNode;
    viewBox: string;
  }
> = {
  [EIcon.ChevronRight]: {
    icon: <ChevronRight />,
    viewBox: '0 0 16 16'
  },
  [EIcon.CheckCircleOutlined]: {
    icon: <CheckCircleOutlined />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ClipboardPlus]: {
    icon: <ClipboardPlus />,
    viewBox: '0 0 16 16'
  },
  [EIcon.DotDotDotVertical]: {
    icon: <DotDotDotVertical />,
    viewBox: '0 0 16 16'
  },
  [EIcon.DotsDrag]: {
    icon: <DotsDrag />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ExternalLink]: {
    icon: <ExternalLink />,
    viewBox: '0 0 16 16'
  },
  [EIcon.GoTo]: {
    icon: <GoTo />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Integration]: {
    icon: <Integration />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Job]: {
    icon: <Job />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Lock]: {
    icon: <Lock />,
    viewBox: '0 0 16 16'
  },
  [EIcon.LockUnlocked]: {
    icon: <LockUnlocked />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Home]: {
    icon: <Home />,
    viewBox: '0 0 20 20'
  },
  [EIcon.MessageAlertSquare]: {
    icon: <MessageAlertSquare />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Settings04]: {
    icon: <Settings04 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Edit16]: {
    icon: <Edit16 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.EyeSlashed]: {
    icon: <EyeSlashed />,
    viewBox: '0 0 14 14'
  },
  [EIcon.Delete]: {
    icon: <Delete />,
    viewBox: '0 0 12 12'
  },
  [EIcon.Close]: {
    icon: <Close />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Plus]: {
    icon: <Plus />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Minimize01]: {
    icon: <Minimize01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ArtificialIntelligenceSpark]: {
    icon: <ArtificialIntelligenceSpark />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Refresh]: {
    icon: <Refresh />,
    viewBox: '0 0 12 11'
  },
  [EIcon.RefreshCircle]: {
    icon: <RefreshCircle />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Expand01]: {
    icon: <Expand01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Eye]: {
    icon: <Eye />,
    viewBox: '0 0 14 10'
  },
  [EIcon.ChipClose]: {
    icon: <ChipClose />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Pause]: {
    icon: <Pause />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Start]: {
    icon: <Start />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Edit01]: {
    icon: <Edit01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ActionClose]: {
    icon: <ActionClose />,
    viewBox: '0 0 12 12'
  },
  [EIcon.InfoCircle]: {
    icon: <InfoCircle />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Save02]: {
    icon: <Save02 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Warning]: {
    icon: <Warning />,
    viewBox: '0 0 24 25'
  },
  [EIcon.Info]: {
    icon: <Info />,
    viewBox: '0 0 24 24'
  },
  [EIcon.Error]: {
    icon: <Error />,
    viewBox: '0 0 22 22'
  },
  [EIcon.Success]: {
    icon: <Success />,
    viewBox: '0 0 24 24'
  },
  [EIcon.ActionSearch]: {
    icon: <ActionSearch />,
    viewBox: '0 0 24 24'
  },
  [EIcon.ChevronDown]: {
    icon: <ChevronDown />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ChevronUp]: {
    icon: <ChevronUp />,
    viewBox: '0 0 16 16'
  },
  [EIcon.FilterLines]: {
    icon: <FilterLines />,
    viewBox: '0 0 16 16'
  },
  [EIcon.RefreshCCW01]: {
    icon: <RefreshCCW01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Container]: {
    icon: <Container />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Activity]: {
    icon: <Activity />,
    viewBox: '0 0 20 20'
  },
  [EIcon.TransitionArrowRight]: {
    icon: <TransitionArrowRight />,
    viewBox: '0 0 75 10'
  },
  [EIcon.FaceSmile]: {
    icon: <FaceSmile />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Feedback]: {
    icon: <Feedback />,
    viewBox: '0 0 20 20'
  },
  [EIcon.AlertTriangle]: {
    icon: <AlertTriangle />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ApiOutlined]: {
    icon: <ApiOutlined />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Download01]: {
    icon: <Download01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Calendar]: {
    icon: <Calendar />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ActivityHeart]: {
    icon: <ActivityHeart />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Folder]: {
    icon: <Folder />,
    viewBox: '0 0 84 63'
  },
  [EIcon.FolderPlus]: {
    icon: <FolderPlus />,
    viewBox: '0 0 80 61'
  },
  [EIcon.Hand]: {
    icon: <Hand />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Pin]: {
    icon: <Pin />,
    viewBox: '0 0 16 16'
  },
  [EIcon.PinLeft]: {
    icon: <PinLeft />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Columns]: {
    icon: <Columns />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Layout]: {
    icon: <Layout />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ArrowUp]: {
    icon: <ArrowUp />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ArrowDown]: {
    icon: <ArrowDown />,
    viewBox: '0 0 16 16'
  },
  [EIcon.MinusCircle]: {
    icon: <MinusCircle />,
    viewBox: '0 0 14 14'
  },
  [EIcon.PlusCircle]: {
    icon: <PlusCircle />,
    viewBox: '0 0 14 14'
  },
  [EIcon.NotFound]: {
    icon: <NotFound />,
    viewBox: '0 0 274 187'
  },
  [EIcon.InternalError]: {
    icon: <InternalError />,
    viewBox: '0 0 312 341'
  },
  [EIcon.Copy]: {
    icon: <Copy />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Code02]: {
    icon: <Code02 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Stars01]: {
    icon: <Stars01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.File04]: {
    icon: <File04 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Variable]: {
    icon: <Variable />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Terminal]: {
    icon: <Terminal />,
    viewBox: '0 0 16 16'
  },
  [EIcon.LinkExternal01]: {
    icon: <LinkExternal01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Python]: {
    icon: <Python />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Shuffle01]: {
    icon: <Shuffle01 />,
    viewBox: '0 0 16 16'
  },
  [EIcon.DeleteArrow]: {
    icon: <DeleteArrow />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Play]: {
    icon: <Play />,
    viewBox: '0 0 14 16'
  },
  [EIcon.CPU]: {
    icon: <CPU />,
    viewBox: '0 0 12 12'
  },
  [EIcon.Switch]: {
    icon: <Switch />,
    viewBox: '0 0 16 16'
  },
  [EIcon.CaretUp]: {
    icon: <CaretUp />,
    viewBox: '0 0 12 12'
  },
  [EIcon.CaretDown]: {
    icon: <CaretDown />,
    viewBox: '0 0 12 12'
  },
  [EIcon.CodeBlockModal]: {
    icon: <CodeBlockModal />,
    viewBox: '0 0 36 36'
  },
  [EIcon.ContextEnrichmentModal]: {
    icon: <ContextEnrichmentModal />,
    viewBox: '0 0 36 36'
  },
  [EIcon.ContextEnrichmentDisabledModal]: {
    icon: <ContextEnrichmentModal disabled />,
    viewBox: '0 0 36 36'
  },
  [EIcon.EnrichmentModal]: {
    icon: <EnrichmentModal />,
    viewBox: '0 0 36 36'
  },
  [EIcon.PostDecisionModal]: {
    icon: <PostDecisionModal />,
    viewBox: '0 0 36 36'
  },
  [EIcon.TicketingModal]: {
    icon: <TicketingModal />,
    viewBox: '0 0 36 36'
  },
  [EIcon.ThreeDots]: {
    icon: <ThreeDots />,
    viewBox: '0 0 16 16'
  },
  [EIcon.SwapRight]: {
    icon: <SwapRight />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ChevronLeft]: {
    icon: <ChevronLeft />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ChevronLeftDouble]: {
    icon: <ChevronLeftDouble />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ChevronRightDouble]: {
    icon: <ChevronRightDouble />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Send]: {
    icon: <Send />,
    viewBox: '0 0 16 16'
  },
  [EIcon.ArcannaDarkLogo]: {
    icon: <ArcannaDarkLogo />,
    viewBox: '0 0 52 52'
  },
  [EIcon.ArrowNarrowRight]: {
    icon: <ArrowNarrowRight />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Database]: {
    icon: <Database />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Puzzle]: {
    icon: <Puzzle />,
    viewBox: '0 0 20 20'
  },
  [EIcon.Chip]: {
    icon: <Chip />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Brain]: {
    icon: <Brain />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Flash]: {
    icon: <Flash />,
    viewBox: '0 0 16 16'
  },
  [EIcon.Square]: {
    icon: <Square />,
    viewBox: '0 0 16 16'
  }
};
