/* eslint-disable max-len */
function Brain() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2913_148)">
        <path
          d="M8.00001 7.352V11.8891C7.9988 12.3082 7.91298 12.7228 7.7477 13.1079C7.58242 13.493 7.34108 13.8408 7.03815 14.1304C6.73522 14.42 6.37694 14.6455 5.98477 14.7933C5.59261 14.941 5.17464 15.0081 4.75592 14.9905C4.3372 14.9729 3.92636 14.8709 3.548 14.6907C3.16965 14.5104 2.83159 14.2556 2.55407 13.9416C2.27656 13.6276 2.06531 13.2607 1.93299 12.8631C1.80066 12.4654 1.74999 12.0451 1.78401 11.6274C1.13601 10.9314 0.726868 9.57029 0.726868 8.43429C0.726868 6.88686 1.48687 5.38172 2.57487 4.78857C2.51592 4.24808 2.62031 3.70232 2.87456 3.22172C3.12882 2.74113 3.52127 2.34777 4.00126 2.09239C4.48126 1.83702 5.02678 1.73135 5.56741 1.78904C6.10805 1.84672 6.61899 2.0651 7.0343 2.416"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01257 6.63879C3.61257 6.54737 2.70857 5.95308 2.57257 4.79651"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.61717 9.99536C2.21031 10.3165 1.88574 11.0434 1.78174 11.6262"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 11.8891C8.00121 12.3082 8.08703 12.7228 8.25231 13.1079C8.41759 13.493 8.65893 13.8408 8.96186 14.1304C9.26479 14.42 9.62307 14.6454 10.0152 14.7932C10.4074 14.941 10.8254 15.0081 11.2441 14.9905C11.6628 14.9729 12.0737 14.8709 12.452 14.6907C12.8304 14.5104 13.1684 14.2556 13.4459 13.9416C13.7235 13.6276 13.9347 13.2607 14.067 12.8631C14.1993 12.4654 14.25 12.0451 14.216 11.6274C14.864 10.9314 15.272 9.57028 15.272 8.43428C15.272 7.93828 15.1943 7.44686 15.0526 6.98743"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3829 9.99536C13.7897 10.3165 14.1143 11.0434 14.2183 11.6262"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.07313 4.92226C7.67313 4.8514 7.67313 4.27655 8.07313 4.20683C8.78361 4.08342 9.44122 3.75118 9.9621 3.25249C10.483 2.7538 10.8435 2.11127 10.9977 1.40683L11.0217 1.29597C11.1086 0.899403 11.6731 0.895975 11.7634 1.29255L11.792 1.42169C11.9521 2.12293 12.3159 2.76122 12.8377 3.25634C13.3594 3.75147 14.0159 4.08135 14.7246 4.20455C15.128 4.27426 15.128 4.85369 14.7246 4.92455C14.0159 5.04774 13.3594 5.37763 12.8377 5.87275C12.3159 6.36788 11.9521 7.00616 11.792 7.7074L11.7634 7.8354C11.672 8.23083 11.1086 8.22855 11.0217 7.83197L10.9977 7.72226C10.8435 7.01783 10.483 6.37529 9.9621 5.8766C9.44122 5.37791 8.78361 5.04567 8.07313 4.92226Z"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2913_148">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Brain;
