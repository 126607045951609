import { EIcon, Icon } from '@arcanna/generic';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

export type TExpandedDrawerNavigationProps = {
  currentIndex: number | undefined;
  totalEntries: number | undefined;
  onIncrement: () => void;
  onDecrement: () => void;
};

function ExpandedDrawerNavigation({ currentIndex, totalEntries, onIncrement, onDecrement }: TExpandedDrawerNavigationProps) {
  const { palette } = useTheme();
  const shouldDisplay = useMemo(() => currentIndex !== undefined && totalEntries !== undefined, [currentIndex, totalEntries]);

  const isFirstEntry = useMemo(() => currentIndex === 1, [currentIndex]);
  const isLastEntry = useMemo(() => currentIndex === totalEntries, [currentIndex, totalEntries]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={1} fontSize="14px" lineHeight="22px" flexShrink={0}>
      <IconButton variant="text" onClick={onDecrement} disabled={isFirstEntry} size="small">
        <Icon name={EIcon.ChevronLeft} htmlColor={isFirstEntry ? palette.secondary[500] : palette.secondary[200]} />
      </IconButton>

      <Typography fontSize="inherit">
        {currentIndex}{' '}
        <Typography component="span" fontSize="inherit" display="inline" color={palette.secondary[300]}>
          of {totalEntries}
        </Typography>
      </Typography>

      <IconButton variant="text" onClick={onIncrement} disabled={isLastEntry} size="small">
        <Icon name={EIcon.ChevronRight} htmlColor={isLastEntry ? palette.secondary[500] : palette.secondary[200]} />
      </IconButton>
    </Stack>
  );
}

export default ExpandedDrawerNavigation;
