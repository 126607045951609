/* eslint-disable max-len */
function RefreshCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.3335 3.41692C11.7477 4.44727 12.6668 6.11629 12.6668 7.99998C12.6668 11.1296 10.1298 13.6666 7.00016 13.6666H6.66683M3.66683 12.5831C2.25261 11.5527 1.3335 9.88367 1.3335 7.99998C1.3335 4.87037 3.87055 2.33332 7.00016 2.33332H7.3335M7.66683 14.9333L6.3335 13.6L7.66683 12.2666M6.3335 3.73332L7.66683 2.39998L6.3335 1.06665"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RefreshCircle;
