import { TUseGetEventsBatch } from './useGetEventsBatch';

export const eventKeys = {
  all: ['events'] as const,

  randoms: () => [...eventKeys.all, 'random'] as const,
  random: (jobId: string | number) => [...eventKeys.randoms(), String(jobId)] as const,

  raws: () => [...eventKeys.all, 'raw'] as const,
  raw: (eventId: string | number) => [...eventKeys.raws(), String(eventId)] as const,

  gets: () => [...eventKeys.all, 'get'] as const,
  get: (eventId: string | number) => [...eventKeys.gets(), String(eventId)] as const,
  getBatch: (params: Omit<TUseGetEventsBatch, 'enabled'>) => [...eventKeys.gets(), 'event-batch', params] as const
};
